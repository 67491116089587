<template>
  <div>
    <div class="text-center my-5">
      <button class="mybtn-blue m-3" @click="$router.push('/home')">
        home
      </button>
      <button class="mybtn m-3" @click="downloadPDF()" v-loading="isDownloading">
        Download As PDF
      </button>
    </div>

    <div id="invoiceContent" v-show="!isMobileOrTablet">
      <div class="header-banner">
        <img src="../../assets/phonebox/logo-white.png" alt="Phonebox Logo" class="main-image" />
        <div class="icon-container">
          <img src="../../assets/phonebox/logo-graphiconly.png" alt="PhoneBox Logo Icon" class="main-icon">
        </div>
      </div>
      <div class="header">
        <h4>PHONEBOX CRITICAL INFORMATION SUMMARY</h4>
      </div>
      <br />
      <p>
        This Critical Information Summary (“CIS”) describes the most important elements of your Mobile Services.
      </p>
      <br />
      <p>
        All capitalized terms in the CIS are defined in your Service Terms and Service Agreement with PhoneBox.
      </p>
      <br />
      <table border="1" class="table-data">
        <tr>
          <td class="table-quarter-col table-bold" colspan="1">Customer Name:</td>
          <td class="table-quarter-col" colspan="1">{{ agreementData.name }}</td>
          <td class="table-quarter-col table-bold" colspan="1">Telephone Number:</td>
          <td class="table-quarter-col" colspan="1">{{ agreementData.phoneNumber }}</td>
        </tr>
        <tr>
          <td class="table-quarter-col table-bold" colspan="1">Account Number:</td>
          <td class="table-quarter-col" colspan="1">{{ agreementData.accountNumber }}</td>
          <td class="table-quarter-col table-bold" colspan="1">Date of Agreement:</td>
          <td class="table-quarter-col" colspan="1">{{ agreementData.dateOfAgreement }}</td>
        </tr>
        <tr>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
        </tr>
        <tr>
          <td class="table-quarter-col table-bold" colspan="4">Mobile Services:</td>
        </tr>
        <tr>
          <td class="table-quarter-col table-bold" colspan="1">Plan Name:</td>
          <td class="table-quarter-col" colspan="1">{{ agreementData.plan.name }}</td>
          <td class="table-quarter-col" colspan="1">
            <span class="table-bold">Monthly Service Fee:</span>
            <sup>(before taxes and discounts)</sup>
          </td>
          <td class="table-quarter-col" colspan="1">{{ agreementData.plan.monthlyServiceFee }}</td>
        </tr>
        <tr>
          <td class="table-quarter-col table-bold" colspan="1">Term:</td>
          <td class="table-quarter-col" colspan="1">{{ agreementData.plan.term }}</td>
          <td class="table-quarter-col" colspan="1"></td>
          <td class="table-quarter-col" colspan="1"></td>
        </tr>
        <tr>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
        </tr>
        <tr>
          <td class="table-quarter-col table-bold" colspan="4">Plan includes:</td>
        </tr>
        <tr>
          <td colspan="4">
            <div class="plan-features">
              <ul>
                <li>{{ agreementData.plan.data }} high speed 4G LTE/5G data</li>
                <li>UNLIMITED Canada wide calling</li>
                <li>UNLIMITED international text and picture messaging</li>
                <li>Voicemail - Up to 35 minutes of messages</li>
                <li>Up to 1,000 minutes of International Calling (List of countries for international calling available
                  at https://gophonebox.com/support.)</li>
                <li>Call Display</li>
              </ul>
            </div>
            <p>
              When using Mobile Services, including any unlimited services, please note that you are subject to
              PhoneBox&apos;s Acceptable Use Policy, provided to you and available at: <a
                href="https://gophonebox.com/terms-of-service">https://gophonebox.com/terms-of-service</a></p>
          </td>
        </tr>
        <tr>
          <td class="table-quarter-col table-bold" colspan="4">Add-ons</td>
        </tr>
        <tr v-show="!agreementData.plan.addOns.length">
          <td class="table-quarter-col table-bold" colspan="1">Add-on Name:</td>
          <td class="table-quarter-col" colspan="1">N/A</td>
          <td class="table-quarter-col" colspan="1">
            <span class="table-bold">Add-on Monthly Fee:</span>
            <sup>(before taxes and discounts)</sup>
          </td>
          <td class="table-quarter-col" colspan="1">N/A</td>
        </tr>
        <tr v-show="!agreementData.plan.addOns.length">
          <td class="table-quarter-col table-bold" colspan="1">Add-on description:</td>
          <td class="table-quarter-col" colspan="3">N/A</td>
        </tr>
        <tr v-show="!agreementData.plan.addOns.length">
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
        </tr>
        <template v-for="(addon) in agreementData.plan.addOns">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <tr>
            <td class="table-quarter-col table-bold" colspan="1">Add-on Name:</td>
            <td class="table-quarter-col" colspan="1">{{ addon.name }}</td>
            <td class="table-quarter-col" colspan="1">
              <span class="table-bold">Add-on Monthly Fee:</span>
              <sup>(before taxes and discounts)</sup>
            </td>
            <td class="table-quarter-col" colspan="1">{{ addon.monthlyFee }}</td>
          </tr>
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <tr>
            <td class="table-quarter-col table-bold" colspan="1">Add-on description:</td>
            <td class="table-quarter-col" colspan="3">{{ addon.desc }}</td>
          </tr>
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <tr>
            <td class="table-quarter-col table-empty" colspan="1"></td>
            <td class="table-quarter-col table-empty" colspan="1"></td>
            <td class="table-quarter-col table-empty" colspan="1"></td>
            <td class="table-quarter-col table-empty" colspan="1"></td>
          </tr>
        </template>
        <tr v-show="!agreementData.plan.promotions.length">
          <td class="table-quarter-col table-bold" colspan="1">Promotion Name:</td>
          <td class="table-quarter-col" colspan="1">N/A</td>
          <td class="table-quarter-col table-bold" colspan="1">Promotional Discount Amount:</td>
          <td class="table-quarter-col" colspan="1">N/A</td>
        </tr>
        <tr v-show="!agreementData.plan.promotions.length">
          <td class="table-quarter-col table-bold" colspan="1">Promotion description:</td>
          <td class="table-quarter-col" colspan="3">N/A</td>
        </tr>
        <tr v-show="!agreementData.plan.promotions.length">
          <td class="table-quarter-col table-bold" colspan="1">Promotion Start Date:</td>
          <td class="table-quarter-col" colspan="3">N/A</td>
        </tr>
        <tr v-show="!agreementData.plan.promotions.length">
          <td class="table-quarter-col table-bold" colspan="1">Promotion End Date:</td>
          <td class="table-quarter-col" colspan="3">N/A</td>
        </tr>
        <tr v-show="!agreementData.plan.promotions.length">
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
        </tr>
        <template v-for="promotion in agreementData.plan.promotions">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <tr>
            <td class="table-quarter-col table-bold" colspan="1">Promotion Name:</td>
            <td class="table-quarter-col" colspan="1">{{ promotion.name }}</td>
            <td class="table-quarter-col table-bold" colspan="1">Promotional Discount Amount:</td>
            <td class="table-quarter-col" colspan="1">{{ promotion.amount }}</td>
          </tr>
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <tr>
            <td class="table-quarter-col table-bold" colspan="1">Promotion description:</td>
            <td class="table-quarter-col" colspan="3">{{ promotion.desc }}</td>
          </tr>
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <tr>
            <td class="table-quarter-col table-bold" colspan="1">Promotion Start Date:</td>
            <td class="table-quarter-col" colspan="3">{{ promotion.startDate }}</td>
          </tr>
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <tr>
            <td class="table-quarter-col table-bold" colspan="1">Promotion End Date:</td>
            <td class="table-quarter-col" colspan="3">{{ promotion.endDate }}</td>
          </tr>
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <tr>
            <td class="table-quarter-col table-empty" colspan="1"></td>
            <td class="table-quarter-col table-empty" colspan="1"></td>
            <td class="table-quarter-col table-empty" colspan="1"></td>
            <td class="table-quarter-col table-empty" colspan="1"></td>
          </tr>
        </template>
        <!-- <tr> -->
        <!-- <td class="table-quarter-col table-bold" colspan="1">One-time Fee Description:</td> -->
        <!-- <td class="table-quarter-col" colspan="1">{{ agreementData.oneTimeFee.desc }}</td> -->
        <!-- <td class="table-quarter-col" colspan="1">
            <span class="table-bold">One-time Fee Amount:</span>
            <sup>(before taxes and discounts)</sup>
          </td> -->
        <!-- <td class="table-quarter-col" colspan="1">{{ agreementData.oneTimeFee.amount }}</td> -->
        <!-- </tr> -->
        <!-- <tr>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
        </tr> -->
        <tr>
          <td class="table-quarter-col table-bold" colspan="1">Cancellation Charges:</td>
          <td colspan="3" rowspan="2">
            Total Cancellation Charge: The lesser of $50 or 10% of the minimum monthly Fees for the
            remaining months of the contract, up to a maximum of 24 months. When your Fixed Term expires, the
            Cancellation Charge will be reduced to $0.00.
          </td>
        </tr>
        <tr>
          <td class="table-empty" colspan="4"></td>
        </tr>
        <tr>
          <td class="table-quarter-col table-bold" colspan="1">Data Overage Fees</td>
          <td class="table-quarter-col" colspan="3">Per GB over data cap: {{ agreementData.overageFees.data }}</td>
        </tr>
        <tr>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td colspan="3">
            Your data services will be suspended if you reach $50 in overage fees in a single monthly
            billing cycle. To restore your data services for the remainder of the monthly billing cycle,
            you must add more data.
          </td>
        </tr>
        <tr>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
          <td class="table-quarter-col table-empty" colspan="1"></td>
        </tr>
        <tr>
          <!-- <td class="table-quarter-col table-bold" colspan="1">Total One-Time Fees:</td>
          <td class="table-quarter-col" colspan="1">{{ agreementData.oneTimeFee.totalFees }}</td> -->
          <td class="table-quarter-col table-bold" colspan="2">Total Monthly Fees:</td>
          <td class="table-quarter-col" colspan="2">{{ agreementData.feesSummary.servicePlan }}</td>
        </tr>
        <tr>
          <!-- <td class="table-quarter-col table-bold" colspan="1">GST/HST:</td> -->
          <!-- <td class="table-quarter-col" colspan="1">{{ agreementData.oneTimeFee.gstOrHst }}</td> -->
          <td class="table-quarter-col table-bold" colspan="2">GST/HST:</td>
          <td class="table-quarter-col" colspan="2">{{ agreementData.feesSummary.gstOrHst }}</td>
        </tr>
        <tr>
          <!-- <td class="table-quarter-col table-bold" colspan="1">PST/QST:</td> -->
          <!-- <td class="table-quarter-col" colspan="1">{{ agreementData.oneTimeFee.pstOrQst }}</td> -->
          <td class="table-quarter-col table-bold" colspan="2">PST/QST:</td>
          <td class="table-quarter-col" colspan="2">{{ agreementData.feesSummary.pstOrQst }}</td>
        </tr>
        <tr>
          <!-- <td class="table-quarter-col table-bold" colspan="1">Grand Total One-Time Fees -->
          <!-- and Taxes for Mobile -->
          <!-- Services: </td> -->
          <!-- <td class="table-quarter-col" colspan="1">{{ agreementData.oneTimeFee.totalFees }}</td> -->
          <td class="table-quarter-col table-bold" colspan="2">Grand Total Monthly Fees and
            Taxes for Mobile Services:</td>
          <td class="table-quarter-col" colspan="2">{{ agreementData.feesSummary.totalFees }}</td>
        </tr>
      </table>
      <br />
      <br />
      <div class="header-body">
        Trial Period
      </div>
      <p>
        If your Mobile Services are subject to Cancellation Charges, you can cancel your Services at no cost within 15
        days of the date when you
        first received Mobile Services, as long as you did not use more than half the data of your permitted monthly
        usage limit.
      </p>
      <br>
      <p>
        If you identify as a person with a disability, you can cancel your Services at no cost within 30 days of the
        date when you first received
        Mobile Services, as long as you did not use more than twice the data of your permitted monthly usage limit.
      </p>
      <div class="header-body">
        Contacting PhoneBox and the CCTS
      </div>
      <p>
        You may contact PhoneBox about Mobile Services by email (<a
          href="mailto:services@gophonebox.com">services@gophonebox.com</a>) or Phone (1-855-886-0505).
      </p>
      <br>
      <p>
        If you have a dispute about Mobile Services, we want to resolve the dispute quickly and fairly and will work
        with you in good faith to do
        so. Please contact us at the coordinates listed above regarding any disputes. You can also contact the
        Commission for Complaints for
        Telecom-television Services (CCTS) at www.ccts-cprst.ca, or call them at 1 888 221-1687. CCTS is the
        telecommunications consumer
        agency designated by the CRTC to resolve certain consumer and small business disputes about telecommunications
        and television services.
        CCTS accepts complaints relating to service delivery, contract disputes, billing, credit management and
        unauthorized transfers of service.
      </p>
    </div>
  </div>
</template>

<script>
import htmlToPdf from "../../utils/htmlToPdf";
import { mapAgreementResponseToObject } from "../../utils/utils";
export default {
  data() {
    return {
      isMobileOrTablet: false,
      isDownloading: false,
      dataCapacity: "",
      agreementData: {
        name: "",
        phoneNumber: "",
        address: {
          isCustomerAndBillingAddressSame: true,
          customer: "",
          billing: "",
        },
        transactionType: "",
        accountNumber: "",
        dateOfAgreement: "",
        placeOfAgreement: "",
        lang: "",
        billingChoice: "",
        paymentMethod: {
          paymentType: "",
          cardDetail: {
            last4Digit: "",
            expiryDate: "",
          }, // Can be Null if Payment method is not Card
        },
        email: "",
        homePhone: "",
        contactNameIfCompany: "",
        contactTitleIfCompany: "",
        isPostpaid: true,
        plan: {
          name: "",
          number: "",
          category: "",
          monthlyServiceFee: "",
          term: "",
          planIncludes: [],
          data: "",
          addOns: [], // {  name: "",  monthlyFee: "",  desc: "",  } // Array of Add ons
          promotions: []  // { name: "", amount: "", duration: "", startDate: "", endDate: "", desc: ""} // Array of Promotions
        },
        // oneTimeFee: {
        //   desc: "",
        //   amount: "",
        //   gstOrHst: "",
        //   pstOrQst: "",
        //   totalFees: "",
        // },
        overageFees: {
          data: "",
        },
        device: {
          model: "",
          colour: "",
          imei: "",
          sim: "",
        },
        feesSummary: {
          servicePlan: "",
          addOns: "",
          gstOrHst: "",
          pstOrQst: "",
          totalFees: "",
        },
        storeInfo: {
          storeAgent: "",
          storeName: "",
          storeAddress: "",
          storeContact: "",
        },
        roamingCharges: {
          voice: "",
          data: "",
          text: "",
        },
        textPictureVideoMessagingFee: {
          original: "",
          premium: "",
        },
        longDistanceCalling: {
          url: "",
        }
      },
      invoiceInfo: {},
      usageInfo: {},
      callInfo: {},
      rogersDailyDataUsage: {},
      //slice this data to two parts for two columns side-by-side display
      rogersDailyDataUsageFirstHalf: {},
      rogersDailyDataUsageSecondHalf: {},
    };
  },
  computed: {
    year() {
      return this.$store.state.pdfInvoiceData.postpaid.year;
    },
    month() {
      return this.$store.state.pdfInvoiceData.postpaid.month;
    },
  },

  methods: {
    downloadPDF() {
      this.isDownloading = true;
      let pdfFileName = "PhoneBox - Critical Information Summary";
      if (this.isMobileOrTablet) {
        this.isMobileOrTablet = false;
        setTimeout(() => {
          htmlToPdf.exportPDFWithoutPage(pdfFileName);
          this.isMobileOrTablet = true;
          this.isDownloading = false;
        }, 1500);
      } else {
        setTimeout(() => {
          htmlToPdf.exportPDFWithoutPage(pdfFileName);
          this.isDownloading = false;
        }, 1500);
      }
    },
    getAgreementData() {
      this.$axios
        .get("Account/GetServiceAggrementInfo")
        .then((response) => {
          this.agreementData = mapAgreementResponseToObject(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.isMobileOrTablet = window.innerWidth <= 1024;
    this.getAgreementData();
  },
};
</script>

<style lang="scss" scoped>
h2 {
  letter-spacing: 1px;
}

h3 {
  font-weight: 500;
  letter-spacing: 1px;
}

p {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

td {
  padding: 4px 8px;
  vertical-align: top
}

#invoiceContent {
  width: 1100px;
  margin: auto;
  padding: 200px 100px 100px 100px;
  margin-bottom: 50px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  position: relative;
}

.header-banner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #0594CA;
  width: 100%;
  height: 100px;
}

.header-banner .main-image {
  position: absolute;
  bottom: 20px;
  right: 100px;
}

.header-banner .icon-container {
  position: absolute;
  bottom: -50%;
  left: 100px;
  background-color: #0594CA;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header {
  text-align: center;
}

.text-white {
  color: #fff;
}

.text-main {
  color: #46cad3;
}

.text-blue {
  color: #2e9ed1;
}

.text-grey {
  color: #b1aeae;
}

.bg-main {
  background-color: #ebf8fa;
}

.bg-white {
  background-color: #fff;
}

.bg-blue {
  background-image: linear-gradient(to right, #197eb1, #44c3d1);
}

.mycard {
  border-radius: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.breakdownBar {
  width: 80%;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.callingInfoBar,
.usageInfoBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.callingInfoBar p,
.usageInfoBar p {
  width: 20%;
  font-weight: 400;
  text-align: center;
  margin: 10px 0px;
}

.callingInfoBarEachRow p {
  margin: 10px 0px;
  font-size: 12px;
  font-weight: 300;
}

.usageInfoBarEachRow p {
  margin: 10px 0px;
  font-size: 13px;
  font-weight: 300;
}

.specialBtn {
  border-radius: 50px;
  color: #fff;
  outline: none !important;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  border: 0px;
  padding: 5px 20px;
  text-transform: uppercase;
}

.staticInfo p {
  font-weight: 300;
}

.mycol {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.table-data {
  width: 100%;
}

.table-half-col {
  width: 50%;
}

.table-quarter-col {
  width: 25%;
}

.table-75-col {
  width: 75%;
}

.table-fifth-col {
  width: 20%;
}

.table-empty {
  height: 33px;
}

.table-bold {
  font-weight: 600;
}

.header-body {
  font-weight: 600;
  margin: 18px 0px;
  text-decoration: underline;
}
</style>
